import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import { FilterDropdownComponent } from '../filter-dropdown/filter-dropdown.component';
import { ZpxApiParamNames } from '@src/app/models/zpx-api.model';
import { filter, first, map } from 'rxjs/operators';
import { AppService } from '@src/app/app.service';

@Component({
  selector: 'app-passholder-group-filter',
  standalone: true,
  imports: [CommonModule, FilterDropdownComponent],
  templateUrl: './passholder-group-filter.component.html',
  styleUrls: ['./passholder-group-filter.component.scss']
})
export class PassholderGroupFilterComponent implements OnInit {
  constructor(private appService: AppService) {}

  @Input() formGroup: FormGroup;
  @Input() defaultOption: string = null;
  formControlName = ZpxApiParamNames.GROUP;
  label = 'Group';

  options: DropdownOption[] = null;

  setGroupOpts(): void {
    this.appService.groups$
      .pipe(
        filter((groups) => Boolean(groups?.length)),
        first(),
        map((groups) => {
          return groups.map((group) => {
            return {
              title: group.name,
              value: group.id
            } as DropdownOption;
          });
        })
      )
      .subscribe((options) => {
        this.options = options;

        if (this.defaultOption) {
          this.formGroup.controls[this.formControlName].setValue(
            this.defaultOption,
            {
              emitEvent: false,
              onlySelf: true
            }
          );
        }
      });
  }

  ngOnInit(): void {
    this.formGroup.addControl(this.formControlName, new FormControl());

    this.setGroupOpts();
  }
}
