import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { Params } from '@angular/router';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  Pass,
  PassholderForTable,
  PASSHOLDER_COLUMN_HEADERS,
  STATUSES,
  ZpxApiPassholderParams,
  Passholder
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Injectable()
export class PassholderTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(false);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  customColumnsForTable$ = new BehaviorSubject<ZonarUITableModel[]>([]);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  data: any[] = [];
  defaultPagination = true;

  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'checkmark',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Checkbox,
      cellType: ZonarUITableCellType.Checkbox,
      checkboxDisabled: () => false,
      cell: (p) => p
    },
    {
      columnDef: 'card_number',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_NUMBER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_number
    },
    {
      columnDef: 'card_status',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_STATUS,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_status
    },
    {
      columnDef: 'last_name',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.last_name
    },
    {
      columnDef: 'first_name',
      header: PASSHOLDER_COLUMN_HEADERS.FIRST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.first_name
    },
    {
      columnDef: 'unique_id',
      header: PASSHOLDER_COLUMN_HEADERS.UNIQUE_ID,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.unique_id
    },
    {
      columnDef: 'group_name',
      header: PASSHOLDER_COLUMN_HEADERS.GROUP_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.group_name
    },
    {
      columnDef: 'card_count',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_COUNT,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_count.toString()
    },
    {
      columnDef: 'last_updated',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_UPDATED,
      sortable: false,
      type: ZonarUITableCellType.Text,
      cellType: ZonarUITableCellType.Icon,
      cell: (p: PassholderForTable) => {
        const dateString = this.setDateString(p.last_updated_ts);
        return dateString;
      }
    }
  ];

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  getStandardColumns(): ZonarUITableModel[] {
    return this.standardColumns;
  }

  setDateString(date: Date): string {
    const dateString = dayjs(date.toDateString()).format('YYYY/MM/DD');
    return dateString;
  }

  getPassData(passholders: Passholder[]): any[] {
    const passes = [];
    passholders.forEach((passholder) => {
      const custom_columns = passholder?.custom_columns;

      const card_count =
        passholder.passes === null ? 0 : passholder.passes?.length;

      if (passholder.passes === null || passholder.passes?.length === 0) {
        passholder.passes = [
          {
            number: null,
            active: false,
            insert_ts: null
          }
        ];
      }
      passholder.passes.forEach((pass: Pass) => {
        let passForTable = {
          card_number: pass.number,
          card_status: pass.active ? STATUSES.ACTIVE : STATUSES.INACTIVE,
          last_name: passholder.last_name,
          first_name: passholder.first_name,
          unique_id: passholder.exsid,
          group_name: passholder.group_name,
          card_count,
          other_cards: passholder.passes,
          last_updated_ts: new Date(passholder.last_updated_ts),
          active: passholder.active,
          zpx_id: passholder.id
        };

        custom_columns?.forEach((c) => {
          passForTable = {
            ...passForTable,
            [c.name]: c.value
          };
        });

        // next lines with riderWithMultipleCards prevents dupe rows in table
        const riderWithMultipleCards = passes.find(
          (p) => p.unique_id === passForTable.unique_id
        );

        if (!riderWithMultipleCards) {
          passes.push(passForTable);
        }
      });
    });
    return passes;
  }

  getEditColumn(): ZonarUITableModel {
    return {
      columnDef: 'edit',
      headerIcon: 'edit',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Icon,
      cellType: ZonarUITableCellType.Icon,
      cell: () => 'edit'
    };
  }

  getCustomColumnsForTable(customTypeColumns: any[]): ZonarUITableModel[] {
    if (customTypeColumns?.length) {
      return customTypeColumns.map((c) => {
        return {
          columnDef: c.name,
          header: c.name,
          sortable: false,
          cellType: ZonarUITableCellType.Text,
          cell: (p: PassholderForTable) => p[c.name]
        };
      });
    }
    return [];
  }

  getPassholdersForTable(params: ZpxApiPassholderParams) {
    this.tableLoading.next(true);
    combineLatest([
      this.zpxApiService.getPassholders(params).pipe(
        catchError((error) => {
          this.tableLoading.next(false);
          return throwError(error);
        })
      ),
      this.zpxApiService.getCustomColumns()
    ]).subscribe(
      ([passholders, customCols]) => {
        this.data = this.getPassData(passholders.data);

        this.customColumnsForTable$.next(
          this.getCustomColumnsForTable(customCols)
        );

        this.tableDataSubject$.next(this.data);

        this.totalResults.next(passholders.total_count);
        this.tableLoading.next(false);
      },
      (error) => {
        this.errorMessage.next(error);
      }
    );
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }
}
