import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ZpxInputComponent } from '../zpx-input/zpx-input.component';
import { FormsModule } from '@angular/forms';
import {
  InnerPassholderPatchBody,
  PassholderForTable,
  PassholderPatchBody,
  STATUSES,
  ZpxApiParamNames
} from '@src/app/models/zpx-api.model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PassholderStatusFilterComponent } from '../passholder-status-filter/passholder-status-filter.component';
import { PassholderTypeFilterComponent } from '../passholder-type-filter/passholder-type-filter.component';
import { PassholderGroupFilterComponent } from '../passholder-group-filter/passholder-group-filter.component';
import { AppService } from '@src/app/app.service';
@Component({
  selector: 'app-passholder-filter-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZpxInputComponent,
    PassholderStatusFilterComponent,
    PassholderTypeFilterComponent,
    PassholderGroupFilterComponent
  ],
  templateUrl: './passholder-filter-bar.component.html',
  styleUrls: ['./passholder-filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassholderFilterBarComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private appService: AppService
  ) {}

  private onDestroy$ = new Subject<void>();

  @Output() sendPatchBody: EventEmitter<PassholderPatchBody> = new EventEmitter(
    null
  );
  @Input() passholder: PassholderForTable = null;

  patchBody: InnerPassholderPatchBody = null;

  formGroup = this.fb.group({});

  formControlDropdownNames = [
    ZpxApiParamNames.TYPE,
    ZpxApiParamNames.GROUP,
    ZpxApiParamNames.ACTIVE
  ];
  formControlInputNames = [
    ZpxApiParamNames.LAST_NAME,
    ZpxApiParamNames.FIRST_NAME,
    'unique_id'
  ];

  formControlInputs = null;

  getCustomColumnInputs() {
    return this.appService.customColumns$;
  }

  getStatusDefaultOption() {
    return this.passholder.card_status === STATUSES.ACTIVE ? '1' : '0';
  }

  getTypeDefaultOption(): Observable<string> {
    return this.appService.passholderTypeId$;
  }

  getGroupDefaultOption(): Observable<string> {
    return this.appService.groups$.pipe(
      map((groups) => {
        return groups.find((group) => group.name === this.passholder.group_name)
          .id;
      })
    );
  }

  setStandardFormInputs() {
    this.formControlInputs = {
      [ZpxApiParamNames.LAST_NAME]: {
        label: 'Last Name',
        value: this.passholder.last_name
      },
      [ZpxApiParamNames.FIRST_NAME]: {
        label: 'First Name',
        value: this.passholder.first_name
      },
      unique_id: {
        label: 'Unique Id',
        value: this.passholder.unique_id
      }
    };
  }

  getPatchRequestBody(
    formName: string,
    formValue: string
  ): PassholderPatchBody {
    const updatedValue =
      formName === ZpxApiParamNames.ACTIVE
        ? Boolean(parseInt(formValue))
        : formValue;

    this.patchBody = {
      ...this.patchBody,
      [formName]: updatedValue
    };

    return {
      passholder_update: this.patchBody
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.setStandardFormInputs();
    this.formGroup.valueChanges
      .pipe(
        filter((changes) => {
          return this.formControlDropdownNames.some((name) => changes[name]);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((changes) => {
        let httpPatchRequestBody: PassholderPatchBody;

        this.formControlDropdownNames.forEach((name) => {
          httpPatchRequestBody = this.getPatchRequestBody(name, changes[name]);
        });

        this.sendPatchBody.emit(httpPatchRequestBody);
      });
  }

  inputKeyPress(changes) {
    let httpPatchRequestBody: PassholderPatchBody = this.getPatchRequestBody(
      changes.formName,
      changes.value
    );

    this.sendPatchBody.emit(httpPatchRequestBody);
  }
}
