import { Injectable } from '@angular/core';
import { HttpService } from '@zonar-ui/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import {
  ZpxApiPassholderParams,
  PassholdersReportHttpResponseBody,
  PassholderType,
  Group,
  PassholderPatchBody,
  ZpxApiCustomColumnParams,
  CustomTypeColumn
} from '../../models/zpx-api.model';
import { AppService } from '@src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ZpxApiService {
  constructor(
    private httpService: HttpService,
    private authHeadersService: AuthHeadersService,
    private getEnvService: GetEnvironmentService,
    private httpClient: HttpClient,
    private appService: AppService
  ) {
    this.getGroups().subscribe();
  }

  url = this.getEnvService.getEnvironmentProperty('apiBase')['url'];
  passholdersUrl = `${this.url}/passholders/report`;
  passholderTypeUrl = `${this.url}/passholder_types`;
  groupsUrl = `${this.url}/zpx-groups`;
  customColumsUrl = `${this.url}/custom-type-column`;
  private _passholderTypes = null;

  getCustomColumns(divisionId?: string): Observable<CustomTypeColumn[]> {
    return this.appService.passholderType$.pipe(
      filter((currentPassholderType) => currentPassholderType !== null),
      first(),
      mergeMap((currentPassholderType) => {
        return this.getPassholderTypes().pipe(
          mergeMap((types) => {
            const typeId = types.find(
              (t) => t.name === currentPassholderType
            ).id;

            this.appService.passholderTypeId$.next(typeId);

            let params = {
              passholder_type_id: typeId
            } as ZpxApiCustomColumnParams;
            if (divisionId) {
              params.division_id = divisionId;
            }
            return this._getCustomColumns(params);
          })
        );
      })
    );
  }

  private _getCustomColumns(
    params: ZpxApiCustomColumnParams
  ): Observable<CustomTypeColumn[]> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        if (headers.hackyDivision) {
          params = {
            ...params,
            division_id: headers.hackyDivision
          };
        }
        delete headers.hackyDivision;
        return this.httpService
          .get(
            this.customColumsUrl,
            new HttpParams({ fromObject: { ...params } }),
            new HttpHeaders(headers)
          )
          .pipe(
            map((response) => {
              const customColumns = response.body as CustomTypeColumn[];
              this.appService.customColumns$.next(customColumns);
              return customColumns;
            })
          );
      })
    );
  }

  getPassholders(
    params: ZpxApiPassholderParams
  ): Observable<PassholdersReportHttpResponseBody> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        if (headers.hackyDivision) {
          params = {
            ...params,
            division_id: headers.hackyDivision
          };
        }
        delete headers.hackyDivision;

        if (headers)
          return this.httpService
            .get(
              this.passholdersUrl,
              new HttpParams({ fromObject: { ...params } }),
              new HttpHeaders(headers)
            )
            .pipe(
              map((response) => {
                return response.body as PassholdersReportHttpResponseBody;
              })
            );
      })
    );
  }

  getPassholderTypes(): Observable<PassholderType[]> {
    if (this._passholderTypes?.length) {
      return of(this._passholderTypes);
    }
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        delete headers.hackyDivision;

        return this.httpService
          .get(this.passholderTypeUrl, null, new HttpHeaders(headers))
          .pipe(
            map((response) => {
              this._passholderTypes = response.body as PassholderType[];
              return response.body as PassholderType[];
            })
          );
      })
    );
  }

  getGroups(): Observable<Group[]> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        delete headers.hackyDivision;

        return this.httpService
          .get(this.groupsUrl, null, new HttpHeaders(headers))
          .pipe(
            map((response) => {
              const groups = response.body as Group[];
              this.appService.groups$.next(groups);
              return groups;
            })
          );
      })
    );
  }

  patchPassholder(id: string, patchBody: PassholderPatchBody): void {
    const url = `${this.url}/passholder/${id}/extended`;
    this.authHeadersService
      .getAuthHeaders()
      .pipe(filter((headers) => headers !== null))
      .subscribe((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        delete headers.hackyDivision;
        // the Http Service from Zonar Pattern Library does not include patch, so have to default to Angular's HTTP module
        this.httpClient
          .patch(url, patchBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              console.error(err);
              return throwError(err);
            })
          )
          .subscribe();
      });
  }
}
