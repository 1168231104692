import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { PermissionsService } from '@zonar-ui/auth';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SelectedCompanyService } from '@app/services/selected-company/selected-company.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHeadersService {
  constructor(
    private authService: AuthService,
    private permsService: PermissionsService,
    private getEnvService: GetEnvironmentService,
    private selectedCompService: SelectedCompanyService
  ) {}

  private _authToken = null;
  jwtHelper = new JwtHelperService();
  appId = this.getEnvService.getEnvironmentProperty('auth')['applicationId'];

  getAuthToken() {
    if (this._authToken !== null) {
      const isExpired = this.jwtHelper.isTokenExpired(this._authToken);

      // if not expired, return the cached token
      if (!isExpired) {
        return of(this._authToken);
      }
    }
    return this.authService.getAccessTokenSilently().pipe(
      map((authToken) => {
        this._authToken = authToken;
        return authToken;
      })
    );
  }

  getAuthHeaders() {
    return combineLatest([
      this.selectedCompService
        .getCompanyFromSideNav()
        .pipe(filter((company) => Boolean(company.value))),
      this.permsService
        .getUserProfiles()
        .pipe(filter((profiles) => Boolean(profiles?.length))),
      this.getAuthToken().pipe(filter((token) => Boolean(token))),
      // TODO: temp Zonar user hack
      this.permsService
        .getDivisionMap()
        .pipe(
          filter((divs) => Boolean(divs) && Boolean(Object.keys(divs)?.length))
        )
    ]).pipe(
      map(([company, profiles, authToken, divisions]) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        const firstDivision = Object.keys(divisions)[0];
        // TODO: temp Zonar user hack for zonar user to pass in division
        let profileId = null;
        const zonarUserProfile = profiles?.find(
          (p) => p.applicationId === this.appId && p.companyId === null
        );

        if (zonarUserProfile) {
          profileId = zonarUserProfile.id;
        } else {
          const profile = profiles?.find(
            (p) =>
              p.applicationId === this.appId && p.companyId === company.value
          );

          profileId = profile?.id;
        }

        if (profileId) {
          const headers = {
            'zonar-owner-id': `User ${profileId}`,
            Authorization: `Bearer ${authToken}`,
            // TODO: temp Zonar user hack for zonar user to pass in division
            hackyDivision: zonarUserProfile ? firstDivision : null
          };
          return headers;
        }
        return null;
      })
    );
  }
}
