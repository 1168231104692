import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiFiltersBarComponent } from '@zonar-ui/filter';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-bar',
  standalone: true,
  imports: [CommonModule, ZuiFiltersBarComponent],
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  constructor() {}

  filterFormGroup = new FormGroup({});

  ngOnInit(): void {
    console.log('Logging from filters bar component');
  }
}
