<mat-dialog-content class="cardholder-modal">
  <h2>Edit Cardholder</h2>
  <mat-tab-group>
    <mat-tab label="Details" fxFlex>
      <app-passholder-filter-bar
        (sendPatchBody)="getPatchBody($event)"
        [passholder]="data"></app-passholder-filter-bar>

      <h4>Row data will go here</h4>
      <div class="cardholder-data">
        <pre>{{data | json}}</pre>
      </div>
    </mat-tab>

    <mat-tab label="History"
      ><h4>History will go here</h4>
      <pre>{{data | json}}</pre>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    class="mat-stroked-button mat-button-base accent-button"
    (click)="patchPassholder()">
    Save
  </button>
  <button
    class="mat-stroked-button mat-button-base white-button"
    (click)="dialogRef.close()">
    Cancel
  </button>
</mat-dialog-actions>
