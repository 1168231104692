export enum PASSHOLDER_TYPES {
  STUDENT = 'student',
  AIDE = 'aide',
  DRIVER = 'driver'
}

export interface ZpxApiCustomColumnParams {
  division_id?: string;
  passholder_type_id: string;
}
export interface InnerPassholderPatchBody {
  passholder_type_id?: string;
  first_name?: string;
  last_name?: string;
  zpx_group_id?: string;
  active?: boolean;

  pass_update?: {
    number: string;
  };

  custom_type_values_update?: { column_id: string; value: string }[];
}

export interface PassholderPatchBody {
  passholder_update: InnerPassholderPatchBody;
}

export enum PASSHOLDER_COLUMN_HEADERS {
  CARD_NUMBER = 'CARD NUMBER',
  CARD_STATUS = 'CARD STATUS',
  LAST_NAME = 'LAST NAME',
  FIRST_NAME = 'FIRST NAME',
  UNIQUE_ID = 'UNIQUE ID',
  GROUP_NAME = 'GROUP NAME',
  CARD_COUNT = 'CARD COUNT',
  LAST_UPDATED = 'LAST UPDATED'
}

export enum STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export interface Pass {
  id?: string;
  company_id?: string;
  division_id?: string;
  passholder_id?: string;
  pass_type_id?: string;
  number: string;
  insert_ts: string;
  deactivate_ts?: string;
  active?: boolean;
  ignored?: boolean;
  ignored_ts?: string;
  last_updated_ts?: string;
}

export interface PassholderForTable {
  card_number: string;
  card_status: STATUSES;
  last_name: string;
  first_name: string;
  unique_id: string;
  group_name: string;
  card_count: number;
  other_cards: Pass[];
  last_updated_ts: Date;
  zpx_id: string;
  class?: string;
  type?: PASSHOLDER_TYPES;
}

export interface ZpxApiPassholderParams {
  division_id?: string;
  passholder_type_string: PASSHOLDER_TYPES;
  limit?: number;
  offset?: number;
}

export interface ZpxFrontendPageParams {
  page: number;
  per_page: number;
}

export interface Group {
  active: boolean;
  company_id: string;
  division_id: string;
  gtc_zpass_location_id: number;
  id: string;
  name: string;
  last_updated_ts: string;
}

export interface CustomTypeColumn {
  id?: string;
  company_id?: string;
  division_id?: string;
  name: string;
  passholder_type_id?: string;
  sequence: number;
  last_updated_ts?: string;
  value?: string;
  gtc_zpass_custom_type_column_id?: number;
}
export interface Passholder {
  active?: boolean;
  company_id: string;
  division_id: string;
  exsid: string;
  first_name: string;
  id: string;
  last_name: string;
  last_updated_ts: string;
  group_name: string;
  passes: Pass[];
  custom_columns: CustomTypeColumn[];
}

export interface CustomTypeValue {
  id: string;
  company_id: string;
  division_id: string;
  passholder_type_id: string;
  custom_type_column_id: string;
  last_updated_ts: string;
  value: string;
  passholder_id: string;
}
export interface PassholdersReportHttpResponseBody {
  total_count: number;
  count: number;
  offset: number;
  data: Passholder[];
}

export interface PassholderType {
  id: string;
  name: PASSHOLDER_TYPES;
}

export enum ZpxApiParamNames {
  ACTIVE = 'active',
  GROUP = 'zpx_group_id',
  TYPE = 'passholder_type_id',
  LAST_NAME = 'last_name',
  FIRST_NAME = 'first_name'
}
