import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {
  PassholderForTable,
  PassholderPatchBody
} from '@src/app/models/zpx-api.model';
import { PassholderFilterBarComponent } from '../passholder-filter-bar/passholder-filter-bar.component';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
@Component({
  selector: 'app-cardholder-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    FlexLayoutModule,
    PassholderFilterBarComponent
  ],
  templateUrl: './passholder-modal.component.html',
  styleUrls: ['./passholder-modal.component.scss']
})
export class PassholderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PassholderModalComponent>,
    private zpxApiService: ZpxApiService
  ) {}

  data = inject<PassholderForTable>(MAT_DIALOG_DATA);

  patchBody: PassholderPatchBody;

  getPatchBody(body: PassholderPatchBody) {
    this.patchBody = body;
  }

  patchPassholder() {
    this.zpxApiService.patchPassholder(this.data.zpx_id, this.patchBody);
  }
}
